import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
  TablePagination,
  Dialog,
  Tabs,
  Tab,
} from '@mui/material';
import { getRolePolicies, getRole, getRoles, deleteRole } from '../api/askTableApi';
import RoleDetail from '../components/RoleDetail';
import RoleDialog from '../components/RoleDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StorageIcon from '@mui/icons-material/Storage';
import { formatDistanceToNow } from 'date-fns';
import { zhCN } from 'date-fns/locale'; 
import './Roles.css';
import Policies from './Policies';
import { useAppContext } from '../components/Context';

const Roles = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [roleDialogOpen, setRoleDialogOpen] = useState(false);
  const [editRole, setEditRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ message: '', code: '' });
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    fetchRoles();
  }, [refresh]);
  // project 更新，重新加载信息
  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const rolesData = await getRoles();
      setRoles(rolesData.items);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch roles:', error);
      setError({ message: error.message, code: error.code });
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleRoleClick = (roleId) => {
    setSelectedRole(roleId);
  };

  const handleRoleDialogClose = () => {
    setRoleDialogOpen(false);
    setEditRole(null);
  };

  const handleRoleEdit = async (roleId) => {
    try {
      const roleData = await getRole(roleId);
      const rolePolicies = await getRolePolicies(roleId);
      setEditRole({ ...roleData, policy_ids: rolePolicies.map(policy => policy.id) });
      setRoleDialogOpen(true);
    } catch (error) {
      console.error('Failed to fetch role:', error);
      setError({ message: error.message, code: error.code });
    }
  };

  const handleRoleCreate = () => {
    setEditRole(null);
    setRoleDialogOpen(true);
  };

  const handleSave = () => {
    setRoleDialogOpen(false);
    fetchRoles();
    setRefresh(!refresh);
  };

  const handleDeleteRole = async (roleId) => {
    if (window.confirm("确定要删除这个 Role 吗？")) {
      try {
        await deleteRole(roleId);
        setRoles(roles.filter((role) => role.id !== roleId));
      } catch (error) {
        console.error('删除 Role 失败:', error);
        setError('删除 Role 失败');
      }
    }
  };

  const handleDetailClick = (roleId) => {
    setSelectedRole(roleId);
    setDetailDialogOpen(true);
  };

  const handleDetailDialogClose = () => {
    setDetailDialogOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box className="item-page">
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        sx={{
          marginBottom: '20px',
          '& .MuiTabs-indicator': {
            display: 'none',
          },
          '& .MuiTabs-flexContainer': {
            borderBottom: '1px solid #000',
            justifyContent: 'flex-start',
          },
          '& .MuiTab-root': {
            minWidth: 'auto',
            textAlign: 'left',
            padding: '0 80px 10px 0',
            color: (theme) => theme.palette.text.subtitle,
            fontSize: '1.5rem',
            position: 'relative',
            fontWeight: 'normal',
            transition: 'font-weight 0.3s ease, color 0.3s ease',
            backgroundColor: 'transparent !important',
            '&.Mui-selected': {
              color: (theme) => theme.palette.text.title,
              fontWeight: 'bold',
            },
            '&:hover': {
              color: (theme) => theme.palette.text.title,
            },
          },
        }}
      >
        <Tab label="角色" disableRipple />
        <Tab label="策略" disableRipple />
      </Tabs>

      {selectedTab === 0 ? (
        <Box>
          <Box className="guide-section">
            <Typography gutterBottom>
            您可以扮演这些角色（Role）来访问数据
            </Typography>
          </Box>
          <Button variant="outlined" onClick={handleRoleCreate}>创建角色</Button>
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>名字</TableCell>
                    <TableCell>创建时间</TableCell>
                    <TableCell>修改时间</TableCell>
                    <TableCell>详细信息</TableCell>
                    <TableCell>操作</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((role) => (
                    <TableRow key={role.id} style={{height: '70px'}}>
                      <TableCell>{role.id}</TableCell>
                      <TableCell>{role.name || '未命名角色'}</TableCell>
                      <TableCell>{formatDistanceToNow(new Date(role.created_at), { addSuffix: true, locale: zhCN })}</TableCell>
                      <TableCell>{formatDistanceToNow(new Date(role.modified_at), { addSuffix: true, locale: zhCN })}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleDetailClick(role.id)}>
                          <StorageIcon/>
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleRoleEdit(role.id)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={(e) => { e.stopPropagation(); handleDeleteRole(role.id); }}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {Array.from(new Array(rowsPerPage - roles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length)).map((_, index) => (
                    <TableRow key={`empty-${index}`}>
                      <TableCell colSpan={6}/>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5]}
                component="div"
                count={roles.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => `页码：${page + 1} / ${Math.ceil(count / rowsPerPage)} `}
                className="pagination-container"
              />
            </TableContainer>
          )}
          <RoleDialog
            open={roleDialogOpen}
            onClose={handleRoleDialogClose}
            onSave={handleSave}
            editRole={editRole}
          />

          <Dialog open={detailDialogOpen} onClose={handleDetailDialogClose}>
            {selectedRole && <RoleDetail roleId={selectedRole} refresh={refresh} onEdit={handleRoleEdit} />}
          </Dialog>
        </Box>
      ) : (
        <Policies />
      )}
    </Box>
  );
};

export default Roles;
