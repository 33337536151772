import axios from 'axios';

const API_BASE_URL = window.env?.REACT_APP_API_BASE_URL


const apiClient = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// 请求拦截器：附加 token
apiClient.interceptors.request.use(config => {
    const token = localStorage.getItem('at_ptoken');

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

const handleResponse = (response) => {
    return response; // 所有2xx响应都视为成功
};

const handleError = (error) => {
    if (error.response) {
        const {status, data} = error.response;
        const errorMessage = data.message || 'An error occurred';
        const errorCode = data.code || status;

        return Promise.reject({message: errorMessage, code: errorCode});
    }

    return Promise.reject(new Error('连接失败，请稍后重试'));
};

// 响应拦截器：统一处理错误
apiClient.interceptors.response.use(handleResponse, handleError);


export default apiClient;
// TODO effect是低效且复杂的,能不用就不用,我记得刚刚有个地方我仅仅只是为了同步两个变量的计算关系就用了, 
    //  react应该有类似计算属性的东西,我一会可以搜一下
// API functions
// 修改后:
export const getDatasources = async (pageSize, pageNumber, order) => {
    const response = await apiClient.get(`/datasources?page_size=${pageSize}&page_number=${pageNumber}&order=${order}`);
    return response.data;
};

export const createDatasource = async (datasource) => {
    const response = await apiClient.post('/datasources', datasource);
    return response.data;
};

export const getDatasourceMeta = async (datasourceId) => {
    const response = await apiClient.get(`/datasources/${datasourceId}/meta`);
    return response.data;
};

export const getRoles = async (pageSize, pageNumber, order, name) => {
    const response = await apiClient.get('/roles', {
        params: {
            page_size: pageSize,
            page_number: pageNumber,
            order,
            name
        }
    });
    return response.data;
};

export const generateSQL = async (payload) => {
    const response = await apiClient.post(`/single-turn/q2s`, payload);
    return response.data;
}

export const generateAnswer = async (payload) => {
    const response = await apiClient.post(`/single-turn/q2a`, payload);
    return response.data;
}

export const getChats = async (pageSize, pageNumber, order) => {
    const response = await apiClient.get(`/chats?page_size=${pageSize}&page_number=${pageNumber}&order=${order}`);
    return response.data;
};

export const getBots = async (pageSize, pageNumber, order) => {
    const response = await apiClient.get(`/bots?page_size=${pageSize}&page_number=${pageNumber}&order=${order}`);
    return response.data;
}

export const getExtAPIs = async (pageSize, pageNumber, order) => {
    const response = await apiClient.get(`/extapis?page_size=${pageSize}&page_number=${pageNumber}&order=${order}`);
    return response.data;
}

export const createBot = async (bot) => {
    const response = await apiClient.post('/bots', bot);
    return response.data;
}

export const createChat = async ({botId, roleId, roleVariables}) => {
    const response = await apiClient.post('/chats', {
        bot_id: botId,
        role_id: roleId,
        role_variables: roleVariables,
    });
    return response.data;
};

export const deleteChats = async (chartId) => {
    const response = await apiClient.delete(`/chats/${chartId}`);
    return response.data;
};

export const getPolicies = async (pageSize, pageNumber, order, name) => {
    const response = await apiClient.get('/policies', {
        params: {
            page_size: pageSize,
            page_number: pageNumber,
            order,
            name
        }
    });
    return response.data;
};

export const createPolicy = async (policy) => {
    const response = await apiClient.post('/policies', policy);
    return response.data;
};

export const deletePolicy = async (policyId) => {
    const response = await apiClient.delete(`/policies/${policyId}`);
    return response.data;
};

export const updatePolicy = async (policyId, policy) => {
    const response = await apiClient.patch(`/policies/${policyId}`, policy);
    return response.data;
};

export const getPolicy = async (policyId) => {
    const response = await apiClient.get(`/policies/${policyId}`);
    return response.data;
};

export const createRole = async (role) => {
    const response = await apiClient.post('/roles', role);
    return response.data;
};

export const updateRole = async (roleId, role) => {
    const response = await apiClient.patch(`/roles/${roleId}`, role);
    return response.data;
};

export const deleteRole = async (roleId) => {
    const response = await apiClient.delete(`/roles/${roleId}`);
    return response.data;
};

export const getRole = async (roleId) => {
    const response = await apiClient.get(`/roles/${roleId}`);
    return response.data;
};

export const getRolePolicies = async (roleId) => {
    const response = await apiClient.get(`/roles/${roleId}/policies`);
    return response.data;
};

export const getDatasource = async (datasourceId) => {
    const response = await apiClient.get(`/datasources/${datasourceId}`);
    return response.data;
};

export const updateDatasource = async (datasourceId, datasource) => {
    const response = await apiClient.patch(`/datasources/${datasourceId}`, datasource);
    return response.data;
}


// 修改前:
export const getChat = async (chat_id) => {
    const response = await apiClient.get(`/chats/${chat_id}`);
    return response.data;
};

export const getExtAPI = async (extAPIId) => {
    const response = await apiClient.get(`/extapis/${extAPIId}`);
    return response.data;
}

export const getFileUploadParams = async () => {
    const response = await apiClient.post('/datasources/upload_params', {});
    return response.data;
};


export const updateDatasourceMeta = async (datasourceId) => {
    const response = await apiClient.post(`/datasources/${datasourceId}/meta`);
    return response.data;
}

export const deleteDatasource = async (datasourceId) => {
    const response = await apiClient.delete(`/datasources/${datasourceId}`);
    return response.data;
}

// Secure Tunnel API
export const getSecureTunnels = async (pageSize, pageNumber, order) => {
    const response = await apiClient.get(`/securetunnels?page_size=${pageSize}&page_number=${pageNumber}&order=${order}`);
    return response.data;
};

export const createSecureTunnel = async (secureTunnel) => {
    const response = await apiClient.post('/securetunnels', secureTunnel);
    return response.data;
};

export const getSecureTunnelLinks = async (secureTunnelId, pageSize, pageNumber, order) => {
    const response = await apiClient.get(`/securetunnels/${secureTunnelId}/links?page_size=${pageSize}&page_number=${pageNumber}&order=${order}`);
    return response.data;
};

export const deleteSecureTunnel = async (secureTunnelId) => {
    const response = await apiClient.delete(`/securetunnels/${secureTunnelId}`);
    return response.data;
};

export const updateBot = async (botId, bot) => {
    const response = await apiClient.patch(`/bots/${botId}`, bot);
    return response.data;
}

// Modified getRoleVariables to optionally include datasource_ids
export const getRoleVariables = async (roleId, datasourceId, botId = '') => {
    const response = await apiClient.get(`/auth/roles/${roleId}/variables`, {
        params: {
            bot_id: botId,
            datasource_ids: datasourceId,
        }
    });
    return response.data;
};


export const getBot = async (botId) => {
    const response = await apiClient.get(`/bots/${botId}`);
    return response.data;
}

export const deleteBot = async (botId) => {
    const response = await apiClient.delete(`/bots/${botId}`);
    return response.data;
}

export const getSQLLog = async (pageSize, pageNumber, order='desc') => {
    const response = await apiClient.get(`/single-turn/q2s`, {
        params:{
            page_size:pageSize,
            page_number:pageNumber,
            order:order
        }
    });
    return response.data;
}


export const getAnswerLog = async (pageSize, pageNumber, order='desc') => {
    const response = await apiClient.get(`/single-turn/q2a`, {
        params:{
            page_size:pageSize,
            page_number:pageNumber,
            order:order
        }
    });
    return response.data;
}


export const createExtAPI = async (extAPI) => {
    const response = await apiClient.post('/extapis', extAPI);
    return response.data;
}

export const updateExtAPI = async (extAPIId, extAPI) => {
    const response = await apiClient.post(`/extapis/${extAPIId}`, extAPI);
    return response.data;
}

export const deleteExtAPI = async (extAPIId) => {
    const response = await apiClient.delete(`/extapis/${extAPIId}`);
    return response.data;
}

export const getExtAPIRoutes = async (extAPIId) => {
    const response = await apiClient.get(`/extapis/${extAPIId}/routes`);
    return response.data;
}

export const createExtAPIRoute = async (extAPIId, route) => {
    const response = await apiClient.post(`/extapis/${extAPIId}/routes`, route);
    return response.data;
}

export const getExtAPIRoute = async (extAPIId, routeId) => {
    const response = await apiClient.get(`/extapis/${extAPIId}/routes/${routeId}`);
    return response.data;
}

export const updateExtAPIRoute = async (extAPIId, routeId, route) => {
    const response = await apiClient.post(`/extapis/${extAPIId}/routes/${routeId}`, route);
    return response.data;
}

export const deleteExtAPIRoute = async (extAPIId, routeId) => {
    const response = await apiClient.delete(`/extapis/${extAPIId}/routes/${routeId}`);
    return response.data;
}
