import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Button, Typography, List, ListItem, ListItemText, Paper, Grid, Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, InputLabel, Select, MenuItem, Snackbar, SnackbarContent, TextField, IconButton
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { getChats, deleteChats } from '../api/askTableApi';
import { useTheme } from '@mui/material/styles';
import { useProjectContext, useAppContext } from './Context';
import { styled, keyframes } from '@mui/material/styles';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useLocation } from 'react-router-dom';

// 从上下文模块导入自定义hook，用于访问全局状态
// 定义动画
const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
`;

// 创建一个带动画的图标组件
const AnimatedIcon = styled(ChatBubbleOutlineIcon)(({ theme }) => ({
  fontSize: '64px',
  color: theme.palette.primary.main,
  animation: `${bounce} 2s infinite`,
}));
// ChatList组件，负责显示聊天列表及其相关操作
const ChatList = () => {
  const theme = useTheme(); // 获取主题对象
  const {chats, setChats} = useAppContext();
  const [error, setError] = useState({ message: '', code: '' }); // 保存错误信息
  const [currentPage, setCurrentPage] = useState(1); // 当前分页的页码
  const [hoveredIndex, setHoveredIndex] = useState(null); // 鼠标悬停的列表项索引
  const { urlProjectId } = useProjectContext();
  const { setIsSetPToken, isSetPToken } = useAppContext();
  const navigate = useNavigate(); // 获取导航对象

  const location = useLocation();
  const chatId = location.pathname.split('/')[4];

  // useEffect用于在组件渲染后，获取聊天数据
  // TODO 注意, 这里的bug是不能通过地址栏直接访问chat页面, 否则会请求不到聊天的历史记录
  //      目前的解决方案是PrivateRoute通知这个组件ptoken设置好了, 但是实现要求ProjectSelector切换后必须不能重定向到chat页面, 否则快速切换项目可能发生不可预知的问题
  //      其他的解决办法也有一些 比如1. 把侧边栏和主内容的路由合起来 
  //                               2. 把chatTab也包裹在PrivateRoute里, 但这样会请求两次token会浪费一些资源 
  useEffect(() => {
    if (isSetPToken) {
      const fetchChatsAndBots = async () => {
        try {
          const chatData = await getChats(24, currentPage, 'desc');
          setChats(chatData.items);
          setIsSetPToken(false);
        } catch (error) {
          setError({ message: error.message, code: error.code });
        }
      };

      fetchChatsAndBots(); // 调用获取聊天数据的函数
    }
  }, [isSetPToken]);

  const handleSelectChat = (chat) => {
    navigate(`/${urlProjectId}/talk/chat/${chat.id}`);
  };

  const handleDeleteChat = async (deleteChatId) => {
    try {
      await deleteChats(deleteChatId); // 请求删除聊天的API
      const updatedChats = chats.filter(chat => chat.id !== deleteChatId); // 过滤掉被删除的聊天
      setChats(updatedChats);
      if (deleteChatId == chatId) {
        navigate(`/${urlProjectId}/talk/chat`);
      }
    } catch (error) {
    }
  };

  // 关闭Snackbar的回调函数，使用useCallback包裹以提高性能
  const handleCloseSnackbar = () => {
    setError({ message: '', code: '' }); // 重置错误状态
  }

  return (
  <>
    <Grid container>
      <Grid item xs={12}>
      <Paper sx={{ overflowY: 'auto', boxShadow: 'none', backgroundColor: (theme) => theme.palette.background.default }}>
        {chats.length > 0 ? (
          <List>
            {chats.map((chat, index) => (
              <ListItem
                button
                key={chat.id}
                onClick={() => handleSelectChat(chat)}
                selected={chatId === chat.id}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                sx={{
                  ...(chatId === chat.id && {
                      backgroundColor: theme.palette.action.selected,
                      '&:hover': { backgroundColor: theme.palette.action.hover },
                    }),
                  position: 'relative',
                }}
              >
                <ListItemText
                  primary={chat.name || '未命名'}
                  secondary={
                    chat.latest_msg !== null
                      ? `${new Date(chat.latest_msg).toLocaleString()}`
                      : `${new Date(chat.created).toLocaleString()}`
                  }
                  style={{
                    padding: '0px', // 设置整个 ListItemText 的 padding
                    marginBottom: '0px', // 设置组件之间的间距
                    marginTop: '0px',
                  }}
                  primaryTypographyProps={{ style: { color: theme.palette.text.third } }}
                  secondaryTypographyProps={{ style: { color: theme.palette.text.primary } }}
                />
                {hoveredIndex === index && (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteChat(chat.id);
                    }}
                    sx={{
                      position: 'absolute',
                      right: '20px',
                      top: '26%',
                      color: 'white',
                      backgroundColor: 'transparent',
                      '&:hover': { backgroundColor: 'transparent', color: 'red' },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </ListItem>
            ))}
          </List>
           ) : (
            <Grid container>
            <Grid item xs={12}>
              <Paper sx={{ overflowY: 'auto', boxShadow: 'none', backgroundColor: (theme) => theme.palette.background.default }}>
                {chats.length > 0 ? (
                  <List>
                    {/* 聊天列表的代码保持不变 */}
                  </List>
                ) : (
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    height: '50vh', 
                    textAlign: 'center',
                    p: 3
                  }}>
                    <AnimatedIcon />
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      这里还是空空如也呢！
                    </Typography>
                  </Box>
                )}
              </Paper>
            </Grid>
          </Grid>
            )}
        </Paper>
      </Grid>
    </Grid>
    <Snackbar
      open={!!error.message}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <SnackbarContent
        message={
          <span>
            <strong>Error {error.code}:</strong> {error.message}
          </span>
        }
      />
    </Snackbar>
  </>

  );
};

export default ChatList;