import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation, useParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import {
  CssBaseline, AppBar, Toolbar, Drawer, Box, Tabs, Tab, Typography,
  Snackbar, Alert, List, Tooltip
} from '@mui/material';

// 导入所有需要的组件和页面
import DatasourceList from './pages/DatasourceList';
import CreateDatasource from './pages/CreateDatasource';
import ExtAPIList from './pages/ExtAPI/ExtAPIList';
import Bot from './pages/Bot';
import SQLGenerationPage from './pages/SQLGenerationPage';
import Roles from './pages/Roles';
import Policies from './pages/Policies';
import SecureTunnel from './pages/SecureTunnel';
import Setting from './pages/Setting/Setting';
import LoginPhone from './pages/LoginPhone';
import TenantInfo from './components/TenantInfo';
import ChatTab from './components/ChatTab';
import DataTab from './components/DataTab';
import SqlTab from './components/SqlTab';
import SqlLog from './components/SqlLog';
import ChatBot from './components/ChatBot';
import LoginPrivate from './pages/LoginPrivate';
import RegisterPrivate from './pages/RegisterPrivate';
import { AppProvider, useAppContext, ProjectProvider, AppProvider1 } from './components/Context';
import themeDark from './style/themeDark';
import themeLight from './style/themeLight';
import UserGuide from './components/UserGuide';
import { changeProject, getProject } from './api/projectApi';
import { useProjectContext } from './components/Context';
import './global.css';
import './App.css';
import { jwtDecode } from 'jwt-decode';
import logo from './assets/asktable1.png';
import ProjectSelector from './components/ProjectSelector';


const drawerWidth = 240;

// 修改 PrivateRoute 组件
const PrivateRoute = ({ element: Component }) => {
  const [isTokenSet, setIsTokenSet] = useState(false);
  const { urlProjectId } = useProjectContext();
  const { setIsSetPToken } = useAppContext();

  // TODO 这里有一个bug, 退出再登录后, IsSetPToken为True
  useEffect(() => {
    const setProjectToken = async () => {
      // TODO 这里是可以解析project_id的, 可以考虑如果没过期并且project_id相同就不请求了,这样会舒服一点
      const data = await changeProject(urlProjectId);
      localStorage.setItem('at_ptoken', data.project_token);
      setIsTokenSet(true);
      setIsSetPToken(true);
    };

    setProjectToken();
  }, []);
  const token = localStorage.getItem('at_token')
  if (token) {
    try {
      const payload = jwtDecode(token);
      const { exp } = payload;
      if (Date.now() >= exp * 1000) {
        return <Navigate to="/login" replace />;
      }
    } catch (error) {
      console.error('Token解析失败:', error);
    }
  }

  if (!isTokenSet) {
    return <div>loading...</div>; // 或者返回一个加载中的组件
  }

  return <Component />;
};
const RootRoute = () => {
  const token = localStorage.getItem('at_ptoken')
  if (token) {
    try {
      const payload = jwtDecode(token);
      const { project_id, exp } = payload;
      if (Date.now() <= exp * 1000) {
        return <Navigate to={`/${project_id}/talk/chat`} replace />;
      }
    } catch (error) {
      console.error('Token解析失败:', error);
    }
  }
  localStorage.removeItem('at_ptoken');
  return <Navigate to="/login" replace />;
};

// 主应用组件
const App = () => (
  <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
    <AppProvider1>
      <ProjectProvider>
        <AppProvider>
          <AppContent />
        </AppProvider>
      </ProjectProvider>
    </AppProvider1>
  </SnackbarProvider>
);

// 应用内容组件
const AppContent = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const hasLoggedIn = localStorage.getItem('hasLoggedIn');
  const [isFirstLogin, setIsFirstLogin] = useState(hasLoggedIn ? false : true);
  const location = useLocation();
  const navigate = useNavigate();
  const fullScreenRoutes = ['/login', "/register"];
  const isFullScreenRoute = fullScreenRoutes.includes(location.pathname);
  console.log("isFullScreenRoute", isFullScreenRoute)

  const { urlProjectId, selectedTab } = useProjectContext();
  const [isSettingPage, setIsSettingPage] = useState(false);

  // 主题切换处理
  const handleThemeModel = () => {
    setIsDarkMode(!isDarkMode);
  };

  // 首次登录检测
  useEffect(() => {
    // 开发者模式
    // try{
    //   if (process.env.REACT_APP_TOKEN) {
    //     localStorage.setItem('at_token', process.env.REACT_APP_TOKEN);
    //     const at_ptoken = localStorage.getItem('at_ptoken')
    //     if (!at_ptoken) {
    //       if (process.env.REACT_APP_DEV_PROJECT) {
    //         navigate(`${process.env.REACT_APP_DEV_PROJECT}`);
    //         console.log("开发者模式有个bug, 不能到登录页")
    //       } else {
    //         console.log("开发者模式但跳转路径没有设置")
    //       }
    //     }
    //   }
    // }catch(error){
    //   console.log("非开发者模式")
    // }
    
    // 没有token就跳转到登录页面
    const token = localStorage.getItem('at_token');
    if (!token) {
      // TODO 先假设登录页会正确跳转路径, 路径里已经有project_id了
      // TODO 有些页面应该和主app也分开, 这里先堆上注册页的逻辑, 原来的代码是navigate('/login')
      if (location.pathname !== "/register") {
        navigate('/login');
      }
    }
    // 有就请求路径里的project_id
    const getData = async (project_id) => {
      // TODO 假设可以正确得到project_id先不考虑没有的情况
      if (project_id) {
        // 
      }
    }
      
    getData(urlProjectId);
  }, [urlProjectId]);

  const handleGuideComplete = () => {
    setIsFirstLogin(false);
    localStorage.setItem('hasLoggedIn', 'true');
    // 可以在这里添加其他逻辑，比如导航到特定页面
  };

  // 首次登录欢迎消息
  // TODO 这里是简单的通过每次路由都运行来使它生效, 但其实感觉也许login和其他的路径分的更清楚一点也许更好
  //       举一个例子, 目前这个页面的useEffect全部都不是给login用的
  useEffect(() => {
    if (isFirstLogin && location.pathname.includes('/talk/chat')) {
      setSnackbarMessage('欢迎使用 AskTable！我们为您准备了一份快速入门指南。');
      setOpenSnackbar(true);
      // 这里可以添加更多的首次登录逻辑，比如打开引导对话框等
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.includes('/setting')) {
      setIsSettingPage(true);
    } else {
      setIsSettingPage(false);
    }
  }, [location.pathname]);

  // 处理登出
  const handleLogout = () => {
    localStorage.removeItem('at_token');
    localStorage.removeItem('at_ptoken');
    navigate('/login');
  };

  // 处理Snackbar关闭
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  // 处理选项卡切换
  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      navigate(`/${urlProjectId}/data/data-source`);
    } else if(newValue === 1) {
      navigate(`/${urlProjectId}/talk/chat`);
    } else{
      navigate(`/${urlProjectId}/talk/sql-generation`);
    }
  };

  return (
    <ThemeProvider theme={isDarkMode ? themeDark : themeLight} >
      <CssBaseline />
      <Box className="App">
      {/* TODO 这里的逻辑是判断路由是不是全屏页面, 感觉不太合理 */}
      {isFullScreenRoute ? (
          <Box
            sx={(theme) => ({
              overflowY: 'auto',
              overflowX: 'hidden',
              '&::-webkit-scrollbar': {
                width: '8px',
                backgroundColor: '#dddddd',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888888',
                borderRadius: '4px',
              },
            })}
          >
            <Routes>
              <Route path="/login" element={window.env.REACT_APP_AUTH_MODE == "email" ? <LoginPrivate /> : <LoginPhone />} />
              <Route path="/register" element={window.env.REACT_APP_AUTH_MODE == "email" ? <RegisterPrivate /> : <LoginPhone />} />
            </Routes>
          </Box>
        ) : 
        (
          <>
            {/* 顶部导航栏 */}
            <AppBar position="fixed" sx={(theme) => ({
              zIndex: theme.zIndex.drawer + 2,
              boxShadow: 'none',
              borderBottom: '1.5px solid #efefef',
            })}>
              <Toolbar sx={(theme) => ({
                backgroundColor: theme.palette.background.paper,
                display: 'flex',
                paddingRight: 5,
              })}>
                <a href="https://www.asktable.com/" target="_blank" rel="noopener noreferrer">
                  <Box component="img" src={logo} alt="logo" sx={{ height: 40, marginLeft: '-10px' }} />
                </a>
                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, minWidth: 0, color: (theme) => theme.palette.text.primary, paddingLeft: 3 }}>
                </Typography>
                <Box sx={{ flexShrink: 0, zIndex: (theme) => theme.zIndex.appBar + 2 }}>
                  <TenantInfo
                    handleLogout={handleLogout}
                    isDarkMode={isDarkMode}
                    handleThemeModel={handleThemeModel}
                  />
                </Box>
              </Toolbar>
            </AppBar>

            {/* 选项卡导航栏 */}
            <AppBar position="fixed" sx={{ zIndex: theme => theme.zIndex.drawer + 1, marginTop: '64px', boxShadow: 'none' }}>
              <Toolbar sx={{ backgroundColor: theme => theme.palette.background.paper, display: 'flex', paddingLeft: 0, paddingRight: 0, height: '54px', minHeight: '54px', maxHeight: '64px', boxShadow: '0px 4px 3px -2px rgba(0, 0, 0, 0.12);' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Typography variant="h6"  component="div" sx={{ flexGrow: 1, maxWidth: '200px', minWidth: '200px',color: theme => theme.palette.text.primary }}>
                    <ProjectSelector />
                  </Typography>
                  <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    sx={{
                      marginLeft: '76px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      '& .MuiTabs-indicator': {
                        backgroundColor: '#ade3d0',
                        height: '3px',
                        marginBottom: '3px',
                      },
                      '& .MuiTab-root': {
                        width: '140px',
                        maxWidth: '140px',
                        textAlign: 'center',
                        color: theme => theme.palette.text.primary,
                        fontWeight: 'normal',
                        fontSize: '1.2rem',
                        transition: 'color 0.3s ease, font-weight 0.3s ease',
                        backgroundColor: 'transparent',
                        marginRight: '22px',
                        '&.Mui-selected': {
                          color: '#005e3c',
                          fontWeight: 'bold',
                        },
                        '&:hover': {
                          fontWeight: 'bold',
                          color: '#006a31',
                        },
                      },
                    }}
                  >
                    <Tooltip title="管理您的数据" arrow>
                      <Tab label="数据" />
                    </Tooltip>
                    <Tooltip title="通过与 Bot 聊天，来查询您的数据。Bot 能够基于您的身份与您对话，您也可以将这些 Bot 分享给其他人。" arrow>
                      <Tab label="聊天" />
                    </Tooltip>
                    <Tooltip title="通过自然语言查询数据，方便您与外部的 Bot 或 AI Agent 应用集成。" arrow>
                      <Tab label="速查" />
                    </Tooltip>
                  </Tabs>
                </Box>
              </Toolbar>
            </AppBar>
            {isFirstLogin && <UserGuide onComplete={handleGuideComplete} />}
            {/* 左侧抽屉菜单 */}
            {!isSettingPage && (
              <Drawer
                variant="permanent"
                className="drawerbox"
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor: (theme) => theme.palette.background.default },
                }}
              >
                <Toolbar />
                <Toolbar />
                <Box sx={{ overflow: 'auto', marginTop: 0 }}>
                  <List sx={{ marginTop: 0, paddingTop: '0px' }}>
                    {/* 只有使用Route时navigate才不会重新挂载, 比如/data/data-source切换到/interaction/api-calling, 不会重新挂载DataTab */}
                    {/* TODO 这里侧边栏ChatTab的数据应该从上下文获取, 不然可能ptoken还没设置就请求了at后端获得所有聊天记录 */}
                    <Routes>
                      <Route path="/:project_id/data/data-source" element={<DataTab />} />
                      <Route path="/:project_id/data/data-source/create" element={<DataTab />} />
                      <Route path="/:project_id/interaction/api-calling" element={<DataTab />} />
                      <Route path="/:project_id/talk/bot" element={<ChatTab />} />
                      <Route path="/:project_id/talk/chat/:chat_id?" element={<ChatTab />} />
                      <Route path="/:project_id/talk/sql-generation" element={<SqlTab />} />
                      <Route path="/:project_id/sql/log" element={<SqlTab />} />
                      <Route path="/:project_id/privilege/roles" element={<DataTab />} />
                      <Route path="/:project_id/privilege/policies" element={<DataTab />} />
                      <Route path="/:project_id/security/secure-tunnel" element={<DataTab />} />
                    </Routes>
                  </List>
                </Box>
              </Drawer>
            )}
          <Box component="main" className="content"
            sx={(theme) => ({
              overflowY: 'auto',
              overflowX: 'hidden',
              height: 'calc(100vh - 110px)',
              background: theme.palette.background.paper,
              marginTop: '110px',
              '&::-webkit-scrollbar': {
                width: '8px',
                backgroundColor: '#dddddd',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888888',
                borderRadius: '4px',
              },
            })}
          >
            <Routes key={urlProjectId}>
              <Route path="/:project-id/setting" element={<PrivateRoute element={Setting} />} />
              <Route path="/:project-id/data/data-source" element={<PrivateRoute element={DatasourceList} />} />
              <Route path="/:project_id/data/data-source/create" element={<PrivateRoute element={CreateDatasource} />} />
              <Route path="/:project_id/interaction/api-calling" element={<PrivateRoute element={ExtAPIList} />} />
              <Route path="/:project_id/talk/bot" element={<PrivateRoute element={Bot} />} />
              <Route path="/:project_id/talk/chat/:chat_id?" element={<PrivateRoute element={() => <ChatBot isDarkMode={isDarkMode} />} />} />
              <Route path="/:project_id/talk/sql-generation" element={<PrivateRoute element={SQLGenerationPage} />} />
              <Route path="/:project_id/sql/log" element={<PrivateRoute element={SqlLog} />} />
              <Route path="/:project_id/privilege/roles" element={<PrivateRoute element={Roles} />} />
              <Route path="/:project_id/privilege/policies" element={<PrivateRoute element={Policies} />} />
              <Route path="/:project_id/security/secure-tunnel" element={<PrivateRoute element={SecureTunnel} />} />
              <Route path="/" element={<RootRoute />} />
            </Routes>
          </Box>
          </>)}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert 
            onClose={handleSnackbarClose} 
            severity="success"
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default App;