import React, { useState, useEffect } from 'react';
import {useNavigate, } from 'react-router-dom';
import {
  Box, Button, Typography, Grid, Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, InputLabel, Select, MenuItem, Snackbar, SnackbarContent, TextField, IconButton
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
// import './Chat.css';
import {  createChat, getRoles, getRoleVariables, getBots, getBot, deleteChats } from '../api/askTableApi';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { useAppContext, useProjectContext } from './Context';
import { getChat } from '../api/askTableApi';

const ChatBot = ({isDarkMode}) => {
  const { urlProjectId } = useProjectContext();
  const [error, setError] = useState({ message: '', code: '' });
  const [bots, setBots] = useState([]);
  const [selectedBot, setSelectedBot] = useState('');
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [roleVariables, setRoleVariables] = useState([]);
  const [roleVariableValues, setRoleVariableValues] = useState({});
  // const [, updateState] = useState();
  // const forceUpdate = useCallback(() => updateState({}), []);
  const [userProfile, setUserProfile] = useState([{ key: '', value: '' }]);
  const {  dialogOpen, setDialogOpen  } = useAppContext();
  const navigate = useNavigate();

  const location = useLocation();
  const chatId = location.pathname.split('/')[4];
  const STATIC_URL = window.env.REACT_APP_STATIC_URL || 'https://cloud.asktable.com';
  const {chats, setChats} = useAppContext();

  useEffect(() => {
    if (chatId) {
      const fetchData = async () => {
        const chat = await getChat(chatId);
        loadChatWidget(chat.id, chat.bot_id, chat.name || '');
      };
      fetchData();
    }
  }, [isDarkMode]);

  useEffect(() => {
    const fetchRolesAndBots = async () => {
      try {
        const botData = await getBots(12, 1, 'desc');
        setBots(botData.items);
        const roleData = await getRoles(20, 1, 'desc');
        setRoles(roleData.items);
      } catch (error) {
        setError({ message: error.message, code: error.code });
      }
    };

    fetchRolesAndBots();

    return () => {
      const existingScript = document.querySelector(`script[src="${STATIC_URL}/chatbot.js"]`);
      const existchatbot = document.getElementById('chatBox');
      if (existingScript) {
        existingScript.parentNode.removeChild(existingScript); // 移除聊天机器人脚本
      }
      if (existchatbot) {
        existchatbot.remove(); // 移除聊天机器人DOM元素
      }
    };
  }, []);

  useEffect(() => {
    if (selectedRole && selectedBot) {
      fetchRoleVariables(selectedRole, selectedBot);
    } else {
      setRoleVariables([]);
      setRoleVariableValues({});
    }
  }, [selectedRole, selectedBot]);


  const handleConfirmCreateChat = async () => {
    if (!selectedBot) {
      alert('请选择一个Bot！');
      return;
    }
    const userProfileData = userProfile.reduce((acc, { key, value }) => {
      if (key) {
        acc[key] = value;
      }
      return acc;
    }, {});

    try {
      const chat = await createChat({
        botId: selectedBot,  // Ensure botId is correctly passed here
        roleId: selectedRole,
        roleVariables: roleVariableValues,
        user_profile: userProfileData,
      });
      setDialogOpen(false);
      setChats([chat, ...chats]);
      navigate(`/${urlProjectId}/talk/chat/${chat.id}`);
    } catch (error) {
      console.error('Failed to create chat:', error);
      setError({ message: error.message, code: error.code });
    }
  };
  // const handleClickFullScreen = ()=>{
  //   document.getElementById('fullscreen-chat').click()
  // }
  const handleCloseSnackbar = () => {
    setError({ message: '', code: '' });
  };

  const handleRoleChange = (roleId) => {
    setSelectedRole(roleId);
  };

  const handleBotChange = (botId) => {
    setSelectedBot(botId);
  };

  const handleRoleVariableChange = (variable, value) => {
    setRoleVariableValues((prevValues) => ({
      ...prevValues,
      [variable]: value,
    }));
  };

  const fetchRoleVariables = async (roleId, botId) => {
    try {
      const ds = await getBot(botId);
      const variableData = await getRoleVariables(roleId,ds.datasource_ids);
      const variables = variableData[ds.datasource_ids] || [];
      setRoleVariables(variables);
      setRoleVariableValues({});
    } catch (error) {
      console.error('Failed to fetch role variables:', error);
      setError({ message: error.message, code: error.code });
    }
  };

  // 关闭bot新建对话框
  const DialogClose = () => {
    navigate(`/${urlProjectId}/talk/chat`);
    setDialogOpen(false)
  }

  const loadChatWidget = (chatId, BotId, Name) => {
    // 预加载CSS样式以隐藏#chatBox
    const style = document.createElement('style');
    style.innerHTML = `
      #chatBox {
        display: none;
      }
    `;
    document.head.appendChild(style);

    var existingScript = document.querySelector(`script[src="${STATIC_URL}/chatbot.js"]`);
    var defaultOpen = true;
    if (existingScript) {
      existingScript.parentNode.removeChild(existingScript); // 移除现有的聊天机器人脚本
    }
    const script = document.createElement('script'); // 创建新的脚本元素
    script.src = `${STATIC_URL}/chatbot.js`;
    window.chatbotConfig = {
      chatId: chatId,
      themeColor: isDarkMode ? 'black' : 'white', // 设置聊天窗口主题颜色
      chatWindowName: Name,
      defaultOpen: defaultOpen,
      model: 'admin',
      BotId: BotId,
    };
    script.async = true;
    let headerMouseDownListener = null;

    const originalAddEventListener = EventTarget.prototype.addEventListener;
    EventTarget.prototype.addEventListener = function (type, listener, options) {
      if (this.matches && this.matches('#chatBox .header') && type === 'mousedown') {
        headerMouseDownListener = listener; // 保存鼠标按下事件监听器
      }
      originalAddEventListener.call(this, type, listener, options);
    };
    script.onload = () => {
      if (window.initializeChatWindow) {
        window.initializeChatWindow(); // 初始化聊天窗口
      }
      const checkAndModifyContent = () => {
        const box = document.getElementById('chatBox');
        if (box) {
          clearInterval(checkInterval); // 找到元素后清除定时器
          let content = document.getElementById('chat-window');
          const icon = document.getElementById('chat-icon');
          if (icon) {
            icon.remove(); // 移除聊天图标
          }
          const fullscreen = document.getElementById('fullscreen-chat');
          if (fullscreen) {
            fullscreen.remove(); // 移除全屏按钮
          }
          const close = document.getElementById('close-chat');
          if (close) {
            close.remove(); // 移除关闭按钮
          }
          content.style.width = 'calc(100vw - 240px)';
          content.style.height = 'calc(100vh - 128px)';
          content.style.bottom = '0';
          content.style.right = '0';
          content.style.left = '';
          content.style.top = '';
          content.style.borderRadius = 0;
          content.style.boxShadow = 'none';
          content.style.zIndex = 10;
          const head = document.querySelector('#chat-window .header');
          if (head) {
            head.style.cursor = 'default';
            if (headerMouseDownListener) {
              head.removeEventListener('mousedown', headerMouseDownListener); // 移除鼠标按下事件监听器
            }
          }
          if (style.parentNode) {
            style.parentNode.removeChild(style);
          }
        }
      };
      const maxTime = 5000; // 最大等待时间 5 秒
      const intervalTime = 10; // 每次检查的时间间隔 10 毫秒
      let elapsedTime = 0;
      
      const checkInterval = setInterval(() => {
        if (elapsedTime >= maxTime) {
          clearInterval(checkInterval); // 超过最大等待时间后停止检查
          console.warn('chatBox 未能在指定时间内加载。');
          return;
        }
        checkAndModifyContent();
        elapsedTime += intervalTime;
      }, intervalTime);
    };
    document.body.appendChild(script); // 将脚本元素添加到文档中
  };

  return (
    <>
      <Grid container spacing={2} sx={{ flexGrow: 1, marginTop: 2 }}>
        <Dialog open={dialogOpen} onClose={() => {DialogClose();}}  >
          <DialogTitle color="primary">选择Bot和角色</DialogTitle>
          <DialogContent>
          <Typography variant="caption">
            * 表示必填项
          </Typography>
            <FormControl fullWidth sx={{ marginBottom: '16px' }}>
              <InputLabel id="bot-label">与谁聊(选择一个聊天机器人)*</InputLabel>
              <Select
                labelId="bot-label"
                value={selectedBot}
                onChange={(e) => handleBotChange(e.target.value)}
                label="与谁聊(选择一个聊天机器人)"
                fullWidth
                MenuProps={{
                  PaperProps: {
                    sx: {
                      width: 'auto',
                      minWidth: '100%'
                    }
                  },
                  MenuListProps: {
                    sx: {
                      padding: 0
                    }
                  }
                }}
              >
                {bots.map((bot) => (
                  <MenuItem key={bot.id} value={bot.id}>
                    {bot.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: '16px' }}>
              <InputLabel id="role-label">你是谁(扮演什么角色？)</InputLabel>
              <Select
                labelId="role-label"
                value={selectedRole}
                onChange={(e) => handleRoleChange(e.target.value)}
                label="你是谁(扮演什么角色？)"
                fullWidth
                MenuProps={{
                  PaperProps: {
                    sx: {
                      width: 'auto',
                      minWidth: '100%'
                    }
                  },
                  MenuListProps: {
                    sx: {
                      padding: 0
                    }
                  }
                }}
              >
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {roleVariables.length > 0 && (
              <Box sx={{ marginBottom: '16px' }}>
                <Typography variant="h6" gutterBottom>
                  参数
                </Typography>
                {roleVariables.map((variable) => (
                  <TextField
                    key={variable}
                    label={variable}
                    value={roleVariableValues[variable] || ''}
                    onChange={(e) => handleRoleVariableChange(variable, e.target.value)}
                    fullWidth
                    sx={{ marginBottom: '16px' }}
                  />
                ))}
              </Box>
            )}
            {/* 暂时先不用用户资料 */}
            {/* <Box sx={{ marginBottom: '16px' }}>
              <Typography variant="h7" gutterBottom color="primary">
                用户资料
              </Typography>
              {userProfile.map((profile, index) => (
                <Box key={index} sx={{ display: 'flex', gap: 2, marginBottom: '16px' }}>
                  <TextField
                    label="Key"
                    value={profile.key}
                    onChange={(e) => handleUserProfileChange(index, 'key', e.target.value)}
                    fullWidth
                  />
                  <TextField
                    label="Value"
                    value={profile.value}
                    onChange={(e) => handleUserProfileChange(index, 'value', e.target.value)}
                    fullWidth
                  />
                  <IconButton
                    onClick={() => removeUserProfileField(index)}
                    color="secondary"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Box>
                <Button onClick={addUserProfileField} color="primary">
                  添加用户资料字段
                </Button>
              </Box>
          </Box> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {setDialogOpen(false);}}>取消</Button>
            <Button onClick={handleConfirmCreateChat} color="primary">
              确认
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Snackbar
        open={!!error.message}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <SnackbarContent
          message={
            <span>
              <strong>Error {error.code}:</strong> {error.message}
            </span>
          }
        />
      </Snackbar>
    </>
  );
};

export default ChatBot;
