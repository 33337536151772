import React, { useState, useEffect } from 'react';
import { getDatasources, deleteDatasource } from '../api/askTableApi';
import { Box, Button, Typography, Grid, Paper, Snackbar, SnackbarContent, IconButton, CircularProgress, Tooltip, Tabs, Tab } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // 导入复制图标
import DataSourceDetail from '../components/DatasourceDetail';
import '../global.css'; // 导入全局样式
import './DatasourceList.css';
import { formatDistanceToNow } from 'date-fns';
import { zhCN } from 'date-fns/locale';
import SecureTunnel from './SecureTunnel';
import { useAppContext,useProjectContext } from '../components/Context';

import { ReactComponent as MysqlIcon } from '../assets/files/mysqlIcon.svg';
import { ReactComponent as TidbIcon } from '../assets/files/tidbIcon.svg';
import { ReactComponent as PostgresqlIcon } from '../assets/files/postgresqlIcon.svg';
import { ReactComponent as OceanBaseIcon } from '../assets/files/oceanbaseIcon.svg';
import { ReactComponent as ExcelIcon } from '../assets/files/excelIcon.svg';
import { ReactComponent as CSVIcon } from '../assets/files/csvIcon.svg';
import { ReactComponent as ClickHouse } from '../assets/files/clickHouse.svg';

const DatasourceList = () => {
  const [datasources, setDatasources] = useState([]);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [selectedDatasourceId, setSelectedDatasourceId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [selectedDBTab, setSelectedDBTab] = useState(0); // 切换选项卡

  useEffect(() => {
    const fetchDatasources = async () => {
      try {
        const data = await getDatasources(50, 1, 'desc');
        setDatasources(data.items);
      } catch (error) {
        console.error('Failed to fetch datasources:', error);
      }
    };

    fetchDatasources();
  }, []);

  // 切换选项卡
  const handleTabChange = (event, newValue) => {
    setSelectedDBTab(newValue);
  };

  const handleSelectDatasource = (datasourceId) => {
    setSelectedDatasourceId(datasourceId);
    setDetailDialogOpen(true);
  };

  const handleCopyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setSnackbarMessage('复制成功');
    } catch (error) {
      setSnackbarMessage('复制失败');
    }
    setSnackbarOpen(true);
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'processing':
        return (
          <Tooltip title="正在分析元数据">
            <CircularProgress
              sx={{ color: '#FFEB3B' }}
              size={24}
            />
          </Tooltip>
        );
      case 'failed':
        return (
          <Tooltip title="分析元数据失败">
            <ErrorOutlineIcon
              sx={{ color: '#bf1717' }}
            />
          </Tooltip>
        );
      case 'success':
        return (
          <Tooltip title="正常可用">
            <CheckCircleOutlineIcon
              sx={{ color: '#8AD8BC' }}
            />
          </Tooltip>
        );
      case 'unprocessed':
        return (
          <Tooltip title="暂未开始">
            <HourglassEmptyIcon
              sx={{ color: '#616161' }}
            />
          </Tooltip>
        );
      default:
        return null;
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleDeleteDatasource = async (datasourceId) => {
    if (window.confirm("确定要删除这个数据源吗？")) {
      try {
        await deleteDatasource(datasourceId);
        setDatasources(datasources.filter(ds => ds.id !== datasourceId));
        setSnackbarMessage('数据源删除成功!');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Failed to delete datasource:', error);
        setSnackbarMessage('删除数据源失败!');
        setSnackbarOpen(true);
      }
    }
  };

  return (
    <Box className="item-page">
      <Tabs
        value={selectedDBTab}
        onChange={handleTabChange}
        sx={{
          marginBottom: '20px',
          '& .MuiTabs-indicator': {
            display: 'none',
          },
          '& .MuiTabs-flexContainer': {
            borderBottom: '1px solid #000', // Tab 下方的分割线
            justifyContent: 'flex-start', // 左对齐
          },
          '& .MuiTab-root': {
            minWidth: 'auto', // 移除最小宽度限制
            textAlign: 'left', // 文本左对齐
            padding: '0 80px 10px 0', // 设置左右padding使得文字与下方内容对齐
            color: (theme) => theme.palette.text.subtitle,
            fontSize: '1.5rem',
            position: 'relative',
            fontWeight: 'normal', // 默认字体正常
            transition: 'font-weight 0.3s ease, color 0.3s ease', // 增加字体粗细变化的过渡效果，并添加颜色变化过渡
            backgroundColor: 'transparent !important', // 去除背景颜色
            '&.Mui-selected': {
              color: (theme) => theme.palette.text.title, // 选中时字体颜色为黑色
              fontWeight: 'bold', // 选中时字体加粗
            },
            '&:hover': {
              color: (theme) => theme.palette.text.title,
            },
          },
        }}
      >
        <Tab label="数据" disableRipple />
        <Tab label="安全隧道" disableRipple />
      </Tabs>


      {selectedDBTab === 0 ? (
        <Box>
          <Box className="guide-section">
            <Typography variant="h7" gutterBottom>
              在这里查看和管理您的数据
            </Typography>
          </Box>
          <Grid container spacing={3} mt={2}>
            {datasources.map((ds) => (
               <Grid item xs={12} sm={12} md={6} lg={4} key={ds.id}>
                <Paper className="item-card">
                  <Box className="item-content">
                    <Box className="item-header">
                    <Typography 
                      className="item-name" 
                      noWrap 
                      onClick={() => handleSelectDatasource(ds.id)}
                      style={{
                        textDecoration: 'none',
                        cursor: 'pointer',
                        textUnderlineOffset: '2px'
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.textDecoration = 'underline';
                        e.target.style.textUnderlineOffset = '2px';
                      }}
                      onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
                    >
                      {ds.name ? ds.name : '未命名数据源'}
                    </Typography>
                      {getStatusLabel(ds.meta_status)}
                      <IconButton className="item-delete" onClick={(e) => { e.stopPropagation(); handleDeleteDatasource(ds.id); }}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2" className="unique-key">ID: {ds.id || '无'}</Typography>
                      <IconButton
                        onClick={() => handleCopyToClipboard(ds.id)}
                        size="small"
                        sx={{
                          ml: 0.5,
                          p: 0.25,
                          color: 'inherit',
                          backgroundColor: 'transparent',
                          '&:hover': {
                            color: '#22684e',
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        <ContentCopyIcon fontSize="inherit" sx={{ fontSize: '0.9rem' }} />
                      </IconButton>
                    </Box>
                    <Typography variant="body2" className="item-time">
                      添加时间: {formatDistanceToNow(new Date(ds.created_at), { addSuffix: true, locale: zhCN })}
                    </Typography>
                  </Box>
                  <Box className="item-background">
                    {ds.engine === 'mysql' && <MysqlIcon className="engine-icon-overlay" />}
                    {ds.engine === 'tidb' && <TidbIcon className="engine-icon-overlay" />}
                    {ds.engine === 'postgresql' && <PostgresqlIcon className="engine-icon-overlay" />}
                    {ds.engine === 'oceanbase' && <OceanBaseIcon className="engine-icon-overlay" />}
                    {ds.engine === 'excel' && <ExcelIcon className="engine-icon-overlay" />}
                    {ds.engine === 'csv' && <CSVIcon className="engine-icon-overlay" />}
                    {ds.engine === 'clickhouse' && <ClickHouse className="engine-icon-overlay" />}
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
          {selectedDatasourceId && (
            <DataSourceDetail
              open={detailDialogOpen}
              onClose={() => setDetailDialogOpen(false)}
              datasourceId={selectedDatasourceId}
            />
          )}
        </Box>
      ) : (
        <SecureTunnel />
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <SnackbarContent
          message={snackbarMessage}
        />
      </Snackbar>
    </Box>
  );
};

export default DatasourceList;
